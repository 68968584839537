nav {
  overflow: hidden;
  background-color: lightgrey;
  width: 100%;
  padding: 5pt;
}

nav * {
    margin: 10pt;
}
